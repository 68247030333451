import axios from "axios";

export const fetchAllResult = (searchInput) => {
  return fetchAllResultAsync(searchInput)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("error fetchAllResult->", error);
    });
};
export const fetchOptions =()=>{
  return fetchResourceGroupAsync()
  .then((data)=>{
    return data;
  })
  .catch((error)=>{
    console.log("error fetchOptions->", error);
  });
}

async function fetchAllResultAsync(searchInput) {
  try {
    const response = await axios({
      method: "GET",
      url:
        "https://airesourcematchingprod.azurewebsites.net/getresourcequeue?searchInput=" +
        // "https://airesourcematchingprod-test.azurewebsites.net/getresourcequeue?searchInput=" +
        // "http://localhost:3001/getresourcequeue?searchInput="
        searchInput,
      headers: { "Access-Control-Allow-Origin": "*", crossorigin: true },
    });
    const body = await response;
    return body;
  } catch (error) {
    console.log("error fetchAllResultAsync->", error);
  }
}

async function fetchResourceGroupAsync() {
    await axios.get("https://airesourcematchingprod-test.azurewebsites.net/getoptions/getParentResourceGroups")
    .then((response)=>{
      localStorage.setItem("ParentResourceGroups", JSON.stringify(response.data["Parent Resource Groups"]));
      localStorage.setItem("Skills", JSON.stringify(response.data["Skills"]));
      localStorage.setItem("ResourceRoles", JSON.stringify(response.data["Resource Roles"]));
    })
    .catch((error)=>{
      console.log(error);
    });
}
